<template>
  <div class="card-wrapper">
    <div
      v-for="post in blogPosts.models"
      :key="post.id"
      class="card post last-on-bottom"
    >
      <img
        class="cover"
        v-lazy="post.featured_image"
        :alt="post.featured_image_alt_text"
        :height="215"
        :width="460"
      />

      <div class="post-details">
        <div class="tags-wrapper">
          <a
            v-for="topic in filterTopics(post.topic_ids)"
            :key="topic.id"
            :href="topic.url"
            class="tag"
            target="_blank"
            rel="noopener"
          >
            {{ topic.name }}
          </a>
        </div>

        <div class="post-author">
          {{ post.authorName }}
        </div>
      </div>

      <a
        class="header-primary"
        :href="post.published_url"
        target="_blank"
        rel="noopener"
      >
        {{ post.name }}
      </a>

      <div class="content">{{ post.meta_description }}</div>

      <p class="bottom">
        <a
          class="btn btn-block gradient gradient-dark btn-outline with-go-arrow"
          :href="post.published_url"
          target="_blank"
          rel="noopener"
        >
          Read more
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import httpClient from '@utils/http_client'

import { BlogPostsList } from '@models/blog_post'
import { BlogTopicsList } from '@models/blog_topic'

export default {
  props: {
    topicId: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    blogPosts: new BlogPostsList(),
    blogTopics: new BlogTopicsList()
  }),
  created() {
    const params = {
      limit: 3,
      archived: false,
      state: 'PUBLISHED'
    }

    if (!!this.topicId) params['topic_id'] = this.topicId

    httpClient
      .get('/blog_posts', { params })
      .then(response => {
        const { posts, topics } = response.data

        this.blogPosts = new BlogPostsList(posts.objects)
        this.blogTopics = new BlogTopicsList(topics.objects)
      })
  },
  methods: {
    filterTopics(topicIds) {
      return this.blogTopics.models.filter(topic => topicIds.includes(topic.id))
    }
  }
}
</script>
